import React from "react";
import { Route, Switch, Redirect, useParams } from "react-router-dom";
import { withRouter } from "react-router";
import loadable from "@loadable/component";
import queryString from "qs";
import Helmet from "react-helmet";
import { track } from "./utils/analytics";
import prefetch from "./prefetch";

import Layout from "./components/PageLayout";
import { IFrameLayout } from "./components/IFrameLayout"

import RNSAnnouncement from "./containers/RNSAnnouncement";
/** broken Lazy:
const RNSAnnouncement = Loadable({
  loader: () => import("./containers/RNSAnnouncement"),
  loading: Noop
});
*/

import PostPage from "pages/post";
import MediaPage from "pages/media";
/** broken Lazy:
   const SinglePost = Loadable({
   loader: () => import("./containers/SinglePost"),
   loading: Noop
   });
   const SingleMedia = Loadable({
   loader: () => import("./containers/SingleMedia"),
   loading: Noop
   })
 */

import ScrollToTop from "./components/ScrollToTop";

import FeedbackBar from "./components/FeedbackBar";
import LoginOverlay from "./containers/LoginOverlay";

import SubscriptionResponseHandler from "./components/SubscriptionResponseHandler";
import CreateWatchlistOverlay from "./components/Watchlist/WatchlistCreateOverlay";

const ErrorPage = loadable(() => import("pages/error"));
const ListingPage = loadable(() => import("./pages/listings"));
const CompanyPage = loadable(() => import("./pages/company"));
const IndexPage = loadable(() => import("./pages/index"));
const SectorPage = loadable(() => import("./pages/sector"));
const AquisIndexPage2 = loadable(() => import("./pages/aqse"));
//const AquisIndexPage = loadable(() => import("./pages/index/AQSE"));
const RNSList = loadable(() => import("./containers/RNSList"));
const WatchlistPage = loadable(() => import("./pages/watchlist-extended"));
const SeriesPage = loadable(() => import("./pages/series"));
const VideosPage = loadable(() => import("./pages/video-archives"));
const ProfilePage = loadable(() => import("pages/members"));
const EditProfilePage = loadable(() => import("./components/EditProfilePage"));
const EditProfilePicturePage = loadable(() =>
  import("./components/EditProfilePicturePage")
);
const SettingsPage = loadable(() => import("./components/SettingsPage"));
const PersonalPage = loadable(() => import("./containers/PersonalPage"));
const Registration = loadable(() => import("pages/registration"));
const SubscriptionsPage = loadable(() => import("./pages/subscription"));
const CheckoutPage = loadable(() => import("./pages/subscription/checkout"));
const CryptoWatch = loadable(() => import("pages/crypto-watch"));
const FundWatch = loadable(() => import("pages/fund-watch"));
const EquityBytes = loadable(() => import("pages/equity-bytes"));
const HomePage = loadable(() => import("./pages/home/index-va.js"));
const ArticlePage = loadable(() => import("./pages/articles"));
const ArticleBody = loadable(() => import("./pages/articles/ArticleBody"));
const ArticlesRecentPage = loadable(() => import("./pages/all-articles"));
const ArticlesCollectionPage = loadable(() =>
  import("pages/articles-collection")
);
const MediaEmbed = loadable(() => import("pages/media/embed.js"));

const AuthorPage = loadable(() => import("./pages/authors"));
const MarketsPage = loadable(() => import("./pages/markets"));
const ExplorePage = loadable(() => import("./pages/explore"));
const SearchPage = loadable(() => import("pages/search"));
const AppPage = loadable(() => import("pages/app"));
const PrivacyPolicyPage = loadable(() =>
  import("pages/privacy-and-cookie-policy")
);
const TermsPage = loadable(() => import("pages/terms-and-conditions"));
const SubscriptionTermsPage = loadable(() => import("pages/terms-and-conditions/subscription-terms"));
const CareersPage = loadable(() => import("pages/careers"));
const AboutPage = loadable(() => import("pages/about"));

export const RouteMap = [
  {
    exact: true,
    path: ["/", "/homepage-beta"],
    async prefetch(store, match, queryClient) {
      return prefetch.home(store, match, queryClient);
    },
    render() {
      return (
        <Layout watchlist footer>
          <HomePage />
        </Layout>
      );
    },
  },
  {
    path: "/company/:slug/:tab?",
    async prefetch(store, match) {
      return await prefetch.company(store, match.params);
    },
    render({ match }) {
      return (
        <Layout watchlist banner={false}>
          <ScrollToTop />
          <CompanyPage />
        </Layout>
      );
    },
  },
  {
    path: "/listings/:exchangeCode/:shortTicker/:tab?",
    async prefetch(store, match) {
      return await prefetch.listingPage(store, match.params);
    },
    render({ match }) {
      return (
        <Layout watchlist banner={false}>
          <ScrollToTop />
          <ListingPage
            exchangeCode={match.params.exchangeCode}
            shortTicker={match.params.shortTicker}
          />
        </Layout>
      );
    },
  },
  {
    path: "/members/:login",
    render({ match }) {
      return (
        <Layout watchlist banner={false}>
          <ProfilePage login={match.params.login} />
        </Layout>
      );
    },
  },
  {
    path: "/edit-profile",
    render() {
      return (
        <Layout watchlist banner={false}>
          <EditProfilePage />
        </Layout>
      );
    },
  },
  {
    path: "/edit-profile-picture",
    render() {
      return (
        <Layout watchlist banner={false}>
          <EditProfilePicturePage />
        </Layout>
      );
    },
  },
  {
    path: "/settings",
    render() {
      return (
        <Layout watchlist banner={false}>
          <SettingsPage />
        </Layout>
      );
    },
  },
  {
    path: "/subscribe",
    render() {
      return (
        <Layout footer banner={false}>
          <SubscriptionsPage />
        </Layout>
      );
    },
  },
  {
    path: "/subscribe-checkout",
    render() {
      return <Redirect to="/subscribe/" />
    },
  },
  {
    path: "/subscription-terms-and-conditions",
    render() {
      return (
        <Layout footer>
          <SubscriptionTermsPage />
        </Layout>
      );
    },
  },
  
  {
    path: "/me",
    render({ history, match, location }) {
      return (
        <Layout watchlist>
          <PersonalPage
            login={match.params.login}
            history={history}
            match={match}
            location={location}
          />
        </Layout>
      );
    },
  },
  {
    exact: true,
    path: "/rns/:categorySlug?",
    render() {
      return (
        <Layout watchlist banner={false}>
          <Helmet 
            title="Regulatory News Service (RNS) Alerts For Today - Vox Markets" 
            description="Explore Vox Markets for the most recent Regulatory News Service (RNS) updates and alerts from AIM and AQSE markets. Discover the winners and losers from the AIM. "
          />
          <RNSList />
        </Layout>
      );
    },
  },
  {
    exact: true,
    path: "/search",
    render() {
      return (
        <Layout watchlist>
          <SearchPage />
        </Layout>
      );
    },
  },
  {
    exact: true,
    path: "/markets",
    render() {
      return <Redirect to="/markets/all" />;
    },
  },
  {
    exact: true,
    path: "/markets/uk-100",
    render() {
      return <Redirect to="/markets/all" />;
    },
  },
  {
    exact: true,
    path: "/markets/:leafname",
    prefetch: prefetch.markets,
    render() {
      return (
        <Layout watchlist footer>
          <MarketsPage />
        </Layout>
      );
    },
  },
  {
    exact: true,
    path: "/uk-stock-markets-sectors/",
    render() {
      return <Layout watchlist footer>
        <ExplorePage />
      </Layout>
    },
  },
  {
    path: "/all-articles/:categorySlug?",
    async prefetch(store, match, queryClient) {
      const { params } = match;
      return await prefetch.allArticles(store, {...params}, queryClient);
    },
      render({ location, match }) {
      return (
        <Layout watchlist footer>
          <ScrollToTop />
          <ArticlesRecentPage />
        </Layout>
      );
      },
  },
  {
    exact: true,
    path: "/articles/:leafname/:params?",
    async prefetch(store, match, queryClient) {
      return await prefetch.article(
        store,
        {
          leafname: match.params.leafname.replace(/\?.*$/g, ""),
        },
        queryClient
      );
    },
    render({ match }) {
      const key = match.params.leafname.replace(/\?.*$/g, "");
      return (
        <Layout watchlist footer>
          <ScrollToTop />
          <ArticlePage leafname={key} />
        </Layout>
      );
    },
  },
  {
    exact: true,
    path: "/articlepreview/:leafname",
    render({ match }) {
      const key = match.params.leafname.replace(/\?.*$/g, "");
      return (
        <Layout banner={false}>
          <ScrollToTop />
          <ArticlePage leafname={key} />
        </Layout>
      );
    },
  },
  {
    exact: true,
    path: "/articlepreviewalone/:id",
    render({ match }) {     
      return (
        <IFrameLayout>
          <ArticleBody articleId={match.params.id} />
        </IFrameLayout>
      );
    },
  },
  {
    exact: true,
    path: "/watchlist-extended",
    render() {
      return (
        <Layout>
          <ScrollToTop />
          <WatchlistPage />
        </Layout>
      );
    },
  },
  {
    exact: true,
    path: "/crypto-watch/:query?",
    render({ history, match, location }) {
      return (
        <Layout>
          <CryptoWatch />
        </Layout>
      );
    },
  },
  {
    exact: true,
    path: "/fund-watch/:issue?/:query?",
    render({ history, match, location }) {
      return (
        <Layout footer>
          <FundWatch
            issue={
              match.params.issue ? parseInt(match.params.issue) : undefined
            }
          />
        </Layout>
      );
    },
  },
  {
    exact: true,
    path: "/equity-bytes/:query?",
    render({ history, match, location }) {
      let openAccess = false;
      if (typeof window !== "undefined") {
        let qs = queryString.parse(window.location.search, {
          ignoreQueryPrefix: true,
        });
        if (qs && qs.shareRef) {
          openAccess = qs.shareRef === "dvclvr";
        }
      }
      return (
        <Layout footer>
          <EquityBytes openAccess={openAccess} />
        </Layout>
      );
    },
  },
  {
    exact: true,
    path: "/(ipo-calendar|ipo-watch|ipowatch)",
    async prefetch(store) {
      return await prefetch.ipoRedirect()
    },
    render() {
      return (
        <Layout watchlist footer>
          <HomePage />
        </Layout>
      );
    },
  },
  {
    exact: true,
    path: "/registration",
    render() {
      return (
        <Layout footer>
          <Registration banner={false} />
        </Layout>
      );
    },
  },
  {
    exact: true,
    path: "/login",
    overlay: true,
    render({ history }) {
      return (
        <Layout banner={false}>
          <LoginOverlay forceOpen={true} callback={() => history.push("/")} />
        </Layout>
      );
    },
  },
  {
    path: "/rns/announcement/:storyId",
    async prefetch(store, match) {
      return await prefetch.story(store, match.params);
    },
    overlay: true,
    render({ history, match, location }) {
      return (
        <RNSAnnouncement
          storyId={match.params.storyId}
          onClose={(e) => {
            return location.key ? history.goBack() : history.push("/rns");
          }}
        />
      );
    },
  },
  {
    path: "/post/:id",
    async prefetch(store, match) {
      return await prefetch.post(store, match.params);
    },
    overlay: true,
    render({ history, match, location }) {
      let qs = queryString.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      let closeTarget = "/";
      if (qs && qs.context) {
        closeTarget = qs.context;
      }
      return (
        match.params.id && (
          <PostPage
            id={match.params.id}
            underlay={false}
            onClose={(e) => {
              return location.key
                ? history.goBack()
                : history.push(closeTarget);
            }}
          />
        )
      );
    },
  },
  {
    path: "/(series|collections)/:leafname",
    async prefetch(store, match, queryClient) {
      return await prefetch.series(store, match.params, queryClient);
    },
    render() {
      return (
        <Layout watchlist>
          <ScrollToTop />
          <SeriesPage />
        </Layout>
      );
    },
  },
  {
    path: "/video-archives/",
    async prefetch(store, match, queryClient) {
      await prefetch.videoArchives(queryClient);
    },
    render() {
      return (
        <Layout watchlist>
          <ScrollToTop />
          <VideosPage />
        </Layout>
      );
    },
  },

  {
    path: "/media/:mediaId/:query?",
    async prefetch(store, match, queryClient) {
      return await prefetch.media(match.params, queryClient);
    },
    overlay: true,
    render({ history, match, location }) {
      let qs = queryString.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      let closeTarget = "/";
      if (qs && qs.context) {
        closeTarget = qs.context;
      }
      return (
        <MediaPage
          context={closeTarget}
          onClose={(e) => {
            return location.key ? history.goBack() : history.push(closeTarget);
          }}
        />
      );
    },
  },
  {
    path: "/mediaembed/:mediaId",
    async prefetch(store, match, queryClient) {
      return await prefetch.media(match.params, queryClient);
    },
    render({ history, match, location }) {
      return (
        <MediaEmbed />
      );
    },
  },
  {
    path: "/(authors|author)/justinwelshy",  
    render() {
      return (
        <Redirect to="/market-talk" />
      );
    },
  },
  {
    path: "/(authors|author)/:login",
    async prefetch(store, match, queryClient) {
      return await prefetch.authorArticles(store, match.params, queryClient);
    },
    render({ match }) {
      return (
        <Layout watchlist footer>
          <ScrollToTop />          
          <AuthorPage login={ match.params.login } />
        </Layout>
      );
    }
  },
  {
    path: "/traderscafe/:params?",
    async prefetch(store, match, queryClient) {
      return await prefetch.authorArticles(store, { login: 'Zak Mir' }, queryClient);
    },
    render({ match }) {
      return (
        <Layout watchlist>
          <ScrollToTop />          
          <AuthorPage 
            login="Zak Mir" 
            metaTitle="Traders Cafe with Zak Mir | Vox Markets" 
            metaImage="https://s3-eu-west-1.amazonaws.com/vox.store.images/cms/traders-cafe-square-logo.png"
            filters={
              {articleCategory:{ neq:'Vox Markets Podcast'} }
            }
          />
          <ScrollToTop />
          <AuthorPage />
        </Layout>
      );
    },
  },
  {
    path: "/sharepickers",
    render() {
      return <Redirect to="/market-talk" />
    },
  },
  {
    path: "/market-talk",
    async prefetch(store, match, queryClient) {
      return await prefetch.authorArticles(
        store,
        { login: "justinwelshy" },
        queryClient,
        {or: [
          {articleCategory:{ eq:'Market Talk'} },
          {articleTitle:{like:'5 Things'}}
        ]}
      );
    },
    render() {
      return (
        <Layout watchlist>
          <ScrollToTop />
          <AuthorPage
            login="justinwelshy"
            metaImage="https://s3.eu-west-1.amazonaws.com/vox.assets.public/site-assets/SIC+VOX.jpg"
            metaTitle="Market Talk with Justin Waite | Vox Markets"
            filters={{or: [
              {articleCategory:{ eq:'Market Talk'} },
              {articleTitle:{like:'5 Things'}}
            ]}}
            excludeSeries={["5b754fa93cdf157d82242135"]}
          />
        </Layout>
      );
    }
  },
  {
    path: "/gci/:categorySlug?/:leafname?",
    async prefetch(store, match, queryClient) {
      return await prefetch.articlesCollection(
        store,
        {leafname:'gci'},
        queryClient
      );
    },
    render({ match }) {
      return (
        <Layout compactFooter theme="GCI">
          <ScrollToTop />
          { match.params.leafname ? <ArticlePage leafname={match.params.leafname} /> : match.params.categorySlug ? <ArticlesRecentPage /> : <ArticlesCollectionPage leafname={'gci'} /> }
        </Layout>
      );
    },
  },
  {
    path: "/index/:leafname",
    async prefetch(store, match) {
      return await prefetch.marketIndex(store, match.params);
    },
    render({ match }) {
      return (
        <Layout watchlist>
          <ScrollToTop />
          <IndexPage leafname={match.params.leafname} />
        </Layout>
      );
    },
  },
{
    path: "/sector/:leafname",
    async prefetch(store, match) {
      //return await prefetch.marketIndex(store, match.params);
    },
    render({ match }) {
      return (
        <Layout watchlist>
          <ScrollToTop />
          <SectorPage leafname={match.params.leafname} />
        </Layout>
      );
    },
},
  {
    path: "/aqse/",
    render({ match }) {
      return (
        <>
          <AquisIndexPage2 />
        </>
      );
    },
  },
  {
    path: "/error/:number",
    render({ match }) {
      return (
        <Layout footer>
          <ErrorPage number={match.params.number} />
        </Layout>
      );
    },
  },
  {
    path: "/app",
    render() {
      return (
        <Layout footer>
          <AppPage />
        </Layout>
      );
    },
  },
  {
    path: "/privacy-and-cookie-policy",
    render() {
      return (
        <Layout footer>
          <PrivacyPolicyPage />
        </Layout>
      );
    },
  },
  {
    path: "/terms-and-conditions",
    render() {
      return (
        <Layout footer>
          <TermsPage />
        </Layout>
      );
    },
  },
  {
    path: "/careers",
    render() {
      return (
        <Layout compactFooter>
          <CareersPage />
        </Layout>
      );
    }
  },
  {
    path: "/about",
    render() {
      return (
        <Layout compactFooter>
          <AboutPage />
        </Layout>
      );
    }
  },
];

    const SwitchRoutes = ({ routes, location, children }) => {
  return (
    <React.Fragment>
      {/*
               Inside the <Switch> are regular full-pages
               We can override tha location passed to <Switch> using location.state.overlayContext
              */}
      <Switch
        location={{
          pathname:
            (location.state && location.state.overlayContext) ||
            location.pathname,
        }}
      >
        {RouteMap.filter((route) => !route.overlay).map((routeProps) => (
          <Route {...routeProps} key={routeProps.path} />
        ))}
        {children}
      </Switch>
      {/*
                Overlay routes outside the <Switch />
                They will match against window.location,
                so we can show e.g. /rns/announcement/<id> overlay,
                and keep the previous page underneath using overlayContext
               */}
      {RouteMap.filter((route) => route.overlay).map((routeProps) => (
        <Route {...routeProps} key={routeProps.path} />
      ))}
    </React.Fragment>
  );
};

class Routes extends React.Component {
  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      track("page");
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }
  shouldComponentUpdate(nextProps){
    return nextProps.location !== this.props.location;
  }
  render() {
    const { location } = this.props;
    return (
      <div>
        <SwitchRoutes routes={RouteMap} location={location} />
        <SubscriptionResponseHandler />
        <LoginOverlay />
        <CreateWatchlistOverlay />
        <FeedbackBar />
      </div>
    );
  }
}

export default withRouter(Routes);
